/* IMPORTS */

import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery, Link } from "gatsby"
import Iframe from "react-iframe"
import Img from "gatsby-image"

import { TwoColumn, HalfColumn, BlueColumn } from "../../global"

/* FUNCTION */

const MerchantForm = () => {
    return (
        <FormSection id="form-section">
            <Headline>Two easy ways to sign up to be a Dili merchant</Headline>
            <Subheader>
                You can apply to be a Dili merchant by filling our online application or downloading it and submitting it independantly. However you choose to apply, learn more about the documents we require <Link to="/apply/documents">here</Link>.
                <br/><br/>
                Before applying, review our Merchant Terms and Conditions <CLink to="https://dilinow.com/static/merchant-terms-66b8821352aa5610e8309b297d61c87c.pdf">here</CLink>.
            </Subheader>
            <ButtonContainer>
                <Link to="https://forms.gle/oJXFZAKgaSZaXMCk7"><CButton>Apply Online</CButton></Link>
                <Link to="/apply/form"><CButton>Download Form</CButton></Link>
            </ButtonContainer>
        </FormSection>
    )
}

export default MerchantForm

/* COMPONENTS */

const FormSection = styled.div`
    padding-top: 40px;
    padding-bottom: 80px;

    @media (max-width: ${props => props.theme.screen.md}) {
        
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        padding-top: 24px;
        padding-bottom: 24px;
    }
`

const FormContainer = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 24px;
    padding-bottom: 80px;
`

const FormEmbed = styled(Iframe)`
`

export const CButton = styled.button`
    background-color: #142630;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
    border-radius: 32px;
    padding: 16px 32px;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    outline: 0px;
    margin-top: 12px;
    margin-left: 12px;
    margin-right: 12px;
`

export const ButtonContainer = styled.div`
    display: flex;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 80px;
    justify-content: center;
`

export const Headline = styled.div`
    color: #142630;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: -0.02em;
    text-align: center;
    margin-top: 48px;
    margin-bottom: 20px;

    @media (max-width: ${props => props.theme.screen.md}) {
        font-size: 28px;
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        font-size: 24px;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        font-size: 24px;
    }
`

export const Subheader = styled.div`
    color: #465F6D;
    font-size: 20px;
    letter-spacing: -0.02em;
    text-align: center;
    margin-top: 48px;
    margin-bottom: 40px;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    line-height: 1.5;

`

export const CLink = styled(Link)`
    color: #0084DD;
`