import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import { motion } from "framer-motion"
import FadeIn from 'react-fade-in';

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import MerchantNav from "../components/common/navigation/merchantnav"

import MerchantFeatures from "../components/sections/merchants/merchantfeatures"
import MerchantSteps from "../components/sections/merchants/merchantsteps"
import MerchantForm from "../components/sections/merchants/merchantform"
import MerchantHeader from "../components/sections/merchants/merchantheader"
import Footer from "../components/sections/footer"

import { SectionDivider, HalfColumn, TwoColumn } from "../components/global"

const Merchants = () => (
  <Layout>
    <Helmet>
      <title>Apply to be a Dili Merchant - Dili</title>
      <meta name="description" content="Sign-up with Dili today & open up a world of convenience for your customers. You can offer your customers the option of making a payment through Dili loans and avail your services." />
    </Helmet>
    <MerchantNav></MerchantNav>
    <FadeIn>
    <MerchantHeader></MerchantHeader>
    <SectionDivider>Greater earning with minimum effort</SectionDivider>
    <MerchantFeatures></MerchantFeatures>
    <SectionDivider>4 easy steps to loan fulfilment</SectionDivider>
    <MerchantSteps></MerchantSteps>
    <SectionDivider id="appform">Apply to be a dili merchant</SectionDivider>
    <MerchantForm></MerchantForm>
    </FadeIn>
    <Footer></Footer>
  </Layout>
)

export default Merchants
