/* IMPORTS */

import React from "react"
import styled from "styled-components"
import { motion} from "framer-motion";

/* IMAGES */

import form from "../../../images/icons/Icon/Form.png"
import collateral from "../../../images/icons/Icon/No-Collateral.png"
import card from "../../../images/icons/Icon/Card.png" 

/* FUNCTION */

const MerchantFeatures = () => {
    return (
        <FeatureGrid>
            <FeatureContainer whileHover={{ scale: 1.1 }}>
                <ImageWrapper><img src={form} alt="Logo" width="100"/></ImageWrapper>
                <FeatureHeadline animate={{ y: -24 }} transition={{ duration: 0.5 }}>
                    Simple sign-up process
                </FeatureHeadline>
                <FeatureSubheadline animate={{ y: -24 }} transition={{ duration: 0.5 }}>
                    Just fill out our online form and we will get in touch with you to set you up for Dili loan options. Once you sign-up, just download the Dili Merchant App on your phone and you are set
                </FeatureSubheadline>
            </FeatureContainer>  
            
            <FeatureContainer whileHover={{ scale: 1.1 }}>
                <ImageWrapper><img src={collateral} alt="Logo" width="100"/></ImageWrapper>
                <FeatureHeadline animate={{ y: -24 }} transition={{ duration: 0.5 }}>
                    Minimal involvement in the loan process
                </FeatureHeadline>
                <FeatureSubheadline animate={{ y: -24 }} transition={{ duration: 0.5 }}>
                    Just send a SMS link to your customer using the Dili Merchant App. The customer downloads the Dili Mobile App or logs in to the Dili website and completes the loan process entirely on his own.
                </FeatureSubheadline>
            </FeatureContainer>

            <FeatureContainer whileHover={{ scale: 1.1 }}>
                <ImageWrapper><img src={card} alt="Logo" width="100"/></ImageWrapper>
                <FeatureHeadline animate={{ y: -24 }} transition={{ duration: 0.5 }}>
                    Direct payments to merchant accounts
                </FeatureHeadline>
                <FeatureSubheadline animate={{ y: -24 }} transition={{ duration: 0.5 }}>
                    Once the loan is approved, Dili partner NBFC/Bank pays the loan proceeds directly into your bank account. The entire process can be completed in a few minutes on the Dili App or website by the customer. Make sure you never lose a customer because the payment never came. 
                </FeatureSubheadline>
            </FeatureContainer>   
        </FeatureGrid> 
    )
}

export default MerchantFeatures

/* COMPONENTS */

const FeatureGrid = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media (max-width: ${props => props.theme.screen.md}) {
        grid-template-columns: repeat(2, 1fr);
       }
`

const FeatureContainer = styled(motion.div)`
    width: 320px;
    margin: 64px;
    align-items: center;

    @media (max-width: ${props => props.theme.screen.md}) {
        width: 240px;
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        width: 180px;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        width: 320px;
        line-height: 1.25;
    }
`

const FeatureHeadline = styled(motion.div)`
    color: #142630;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: -0.02em;
    text-align: center;
    margin-top: 48px;
    line-height: 1.2;

    @media (max-width: ${props => props.theme.screen.md}) {
        font-size: 28px;
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        font-size: 24px;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        font-size: 24px;
    }
`

const FeatureSubheadline = styled(motion.div)`
    color: #465F6D;
    font-size: 18px;
    margin-top: 12px;
    line-height: 1.5;
    text-align: center;

    @media (max-width: ${props => props.theme.screen.md}) {
        font-size: 16px;
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        font-size: 14px;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        font-size: 14px;
    }
`

const ImageWrapper = styled.div`
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: ${props => props.theme.screen.md}) {
     justify-self: center;
    }
`