/* IMPORTS */

import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import { useState } from "react";
import { motion, AnimateSharedLayout } from "framer-motion";

import { TwoColumn, HalfColumn, BlueColumn } from "../../global"

/* IMAGES */

import form from "../../../images/icons/Icon/Form.png"
import phone from "../../../images/icons/Icon/Phone.png"
import store from "../../../images/icons/Icon/Store.png"
import card from "../../../images/icons/Icon/Card.png" 

import step1 from "../../../images/steps/merchant/step1.png"
import step2 from "../../../images/steps/product/step2.png"
import step3 from "../../../images/steps/product/step3.png"
import step4 from "../../../images/steps/product/step4.png"

/* FUNCTION */

export const screens = [
    {
      title: "Step 1",
      text: "As a Dili merchant, use our service to push a link to the customer’s mobile via SMS",
      color: "#ff0055",
    },
    {
      title: "Step 2",
      text: "Ask the customer to use the link provided in the message to download the Dili Mobile App and register",
      color: "#0099ff"
    },
    {
      title: "Step 3",
      text: "Help the customer follow the easy step-by-step procedure on the Dili App to get a loan",
      color: "#22cc88"
    },
    {
      title: "Step 4",
      text: "The loan amount after all deductions will be transferred to your bank account directly",
      color: "#ffaa00"
    }
  ];

export const icons = [store, phone, form, card]
export const imgs = [step1, step2, step3, step4]
  

const MerchantSteps = () => {

    const [selected, setSelected] = useState([0, "As a Dili merchant, use our service to push a link to the customer’s mobile via SMS."]);

    return (
        <div>
            <TwoColumn>
                <HalfColumn>
                    <TextContainer>
                        <AnimateSharedLayout>
                            <TabList style={{ transform: "translateZ(0)" }}>
                                {screens.map(({ title, color }, i) => (
                                <Tab
                                    animate
                                    key={i}
                                    
                                    onClick={() => setSelected([i, screens[i].text])}
                                    style={{ color: i === selected[0] ? "#142630" : "#7A8C96" }}
                                    whileHover={{ scale: 1.1 }}
                                >
                                    {i === selected && (
                                    <motion.div
                                        layoutId="underline"
                                        className="underline"
                                        style={{ backgroundColor: color }}
                                    />
                                    )}
                                    {title}
                                </Tab>
                                ))}
                            </TabList>
                        </AnimateSharedLayout>
                        <LabelContainer>
                            <img src={icons[selected[0]]} alt="Logo" width="100"/>
                        </LabelContainer>
                        <HeaderText>
                            {selected[1]}
                        </HeaderText>
                    </TextContainer>
                </HalfColumn>
                <ImageColumn>
                    <StepImg src={imgs[selected[0]]}></StepImg>
                </ImageColumn>
            </TwoColumn>
        
        </div>
    )
}

export default MerchantSteps

/* COMPONENTS */

const TextContainer = styled.div`
    margin: 140px 80px;
    margin-top: 80px;
    @media (max-width: ${props => props.theme.screen.md}) {
        margin: 80px 64px;
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        margin: 80px 64px;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        margin: 80px 64px;
    }
`

const TabList = styled.ol`
    list-style: none;
    padding: 0;
    margin-bottom: 40px;
    user-select: none;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: ${props => props.theme.screen.xs}) {
        justify-content: center;
    }
`

const Tab = styled(motion.li)`
    padding: 12px 24px;
    background: #E0F2FC;
    color: white;
    border-radius: 48px;
    margin-right: 24px;
    margin-bottom: 24px;
    user-select: none;
    font-size: 18px;
    cursor: pointer;
    white-space: nowrap;

    @media (max-width: ${props => props.theme.screen.md}) {
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        color: #7A8C96;
        background: white;
        font-size: 16px;
        margin-right: 24px;
        padding: 0;
        
    }
`

const Headline = styled.div`
    color: #142630;
    font-size: 48px;
    font-weight: bold;
    letter-spacing: -0.02em;

    @media (max-width: ${props => props.theme.screen.md}) {
        font-size: 32px;
        line-height: 1.25;
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        font-size: 32px;
        line-height: 1.25;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        font-size: 24px;
        line-height: 1.5;
        text-align: center;
    }
`

const HeaderText = styled.div`
    color: #142630;
    font-size: 32px;
    letter-spacing: -0.02em;
    line-height: 1.25;

    @media (max-width: ${props => props.theme.screen.md}) {
        font-size: 32px;
        line-height: 1.25;
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        font-size: 32px;
        line-height: 1.25;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        font-size: 24px;
        line-height: 1.5;
        text-align: center;
    }
`

const StepNumber = styled.div`
    height: 48px;
    line-height: 48px;
    width 48px;
    color: white;
    background-color: #7A8C96;
    border-radius: 48px;
    text-align: center;
    margin-left: 40px;
`

const LabelContainer = styled.div`
    margin-bottom: 40px;
    display: flex;
    align-items: center;

    @media (max-width: ${props => props.theme.screen.xs}) {
        display: none;
    }
`
export const ImageColumn = styled(BlueColumn)`
`

export const StepImg = styled.img`
    display: inline-block;
    vertical-align: middle;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 120px;

    @media (max-width: ${props => props.theme.screen.xs}) {
        width: 100%;
        margin: 0;
        margin-top: 0px;
        margin-bottom: 40px;
    }
`