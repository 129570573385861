/* IMPORTS */

import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery, Link } from "gatsby"
import { motion } from "framer-motion"

import { TwoColumn, HalfColumn, BlueColumn } from "../../global"
import headerimg from "../../../images/merchantheader.png"
import { AnchorLink } from "gatsby-plugin-anchor-links";

/* FUNCTION */

const MerchantHeader = () => {
    return (
        <HeaderContainer>
            <Headline>
                We not only help enhance people’s lives, but also your store’s earning potential
            </Headline>
            <HeaderText>
                As a merchant offering the best, you know quality comes at a price. Unfortunately, it also means even customers with a strong desire to buy, often cannot. So, they pick the easy way out. Promise to call or return, only to never come back. Here’s the good news. We have just the solution for that. You can now offer your customers the option of making a payment through a loan and avail of your services. 
                <br/> <br/>
                Sign-up with Dili today and open up a world of convenience for your customers. Dili is online and works through a simple mobile App and a website. You only need to send the customer an SMS containing the link to download the App using the Dili mobile utility provided to you. The customer simply follows instructions on the App or website to get the loan. The loan amount, after deductions, is paid directly to you. No more waiting to receive the payment from the customer. So, give your business a boost.Sign up with Dili today.
            </HeaderText>
            <ButtonContainer>
                <CButton>
                    Get Dili Merchant App
                </CButton>
                <AnchorLink to="/merchants#appform" title="Apply as a merchant">
                <CButton>
                    Register as Merchant
                </CButton>
                </AnchorLink>
            </ButtonContainer>
            <HeaderImage src={headerimg} alt="Zero cost EMI education loan - Dili">
            </HeaderImage>
        </HeaderContainer>
    )
}

export default MerchantHeader

/* COMPONENTS */

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 180px;
    padding-bottom: 80px;
    text-align: center;
`

const Headline = styled.div`
    color: #142630;
    font-size: 48px;
    font-weight: bold;
    letter-spacing: -0.02em;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    line-height: 1.2;

    @media (max-width: ${props => props.theme.screen.md}) {
        font-size: 48px;
        width: 80%;
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        font-size: 48px;
        width: 80%;
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        font-size: 36px;
        width: 80%
    }
`

const HeaderText = styled.div`
    color: #465F6D;
    font-size: 18px;
    margin-top: 32px;
    line-height: 1.5;
    width: 60%;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: ${props => props.theme.screen.md}) {
        width: 80%
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        width: 80%
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        width: 80%
    }
`

const HeaderButton = styled(motion.div)`
    font-weight: 400;
    width: 240px;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    color: white;
    letter-spacing: -0.02em;
    display: block;
    margin-top: 40px;
    cursor: pointer;
    white-space: nowrap;
    background: #7A8C96;
    border-radius: 32px;
    padding: 16px 32px;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    outline: 0px;
    &:hover {
        box-shadow: #7A8C96 0px 2px 10px 0px;
    }
    @media (max-width: ${props => props.theme.screen.md}) {
    }
    @media (max-width: ${props => props.theme.screen.sm}) {
    }
`

const HeaderImage = styled.img`
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    border-radius: 20px;

    @media (max-width: ${props => props.theme.screen.md}) {
        width: 80%
    }

    @media (max-width: ${props => props.theme.screen.sm}) {
        width: 80%
    }

    @media (max-width: ${props => props.theme.screen.xs}) {
        width: 80%
    }
`

const FormSection = styled.div`
    padding-top: 80px;
    padding-bottom: 80px;
`

export const BtnIcon = styled.img`
  width: 15px;
  height: 18px;
  margin-right: 12px;
`
export const BtnText = styled.div`
`

export const CButton = styled.button`
  background-color: #142630;
  border: none;
  color: white;
  padding: 20px 20px 20px 20px;
  font-size: 18px;
  cursor: pointer;
  display: "flex";
  justifyContent: "center";
  border-radius: 32px;
  padding: 16px 32px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 12px;
  margin-right: 16px;
`

export const FilmButton = styled(CButton)`
  background-color: #E87D00;
`

export const ButtonContainer = styled.div`
 display: flex;
 margin-left: auto;
margin-right: auto;
margin-top: 24px;
`